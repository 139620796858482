import axios from 'axios';
import { Alert } from  '../components/ui/Alert';
// apikey: 'EnbX12j02DDHFrAoqjaq3FIkmTGncrrk',


export const AcsApi = axios.create({
  baseURL: 'https://acsapi-portal.azurewebsites.net',
  headers: {
    Accept: 'application/json',
    //sessionid: localStorage.getItem('token')
  }
});

export default AcsApi;

export const ApiCatch = (error) => {

  console.error('Error:', error);
  if (error.status === 422) {
    let errortext = ''
    if (error.response.data.fault.detail.rootcause.globalErrors?.length > 0) {
      errortext = error.response.data.fault.detail.rootcause.globalErrors[0].userMessage;
    }
    else if (error.response.data.fault.detail.rootcause.fieldsErrors?.length > 0) {
      errortext = error.response.data.fault.detail.rootcause.fieldsErrors[0].fieldName;
      errortext += ' ' + error.response.data.fault.detail.rootcause.fieldsErrors[0].userMessage;
    }
    Alert('Error ', errortext, 'error');
  }
  else if (error.status === 401) {
    Alert('Error ', 'Your session has expired', 'error');
  }
  else Alert('Error', 'Unhandled Error ' + error.status, 'error');
} 