import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentDate } from '../reservas/helpers/fechaActual';
import { Button, Form, Row, Card, Table, Input, Col } from 'antd';
import { ListBookingsTable } from './ListBookingsTable';
import { ListBookingsform } from './ListBookingsForm';
import { Spinner } from '../ui/Spinner';
import { formStyle, sectionStyle, cardHeaderStyle, reducedMarginFormItem } from '../formStyles';
import * as s from '../formStyles';
import { Alert } from '../ui/Alert';
import {AcsApi, ApiCatch }from '../../api/AcsApi';
import './ListBookings.css';

export const ListBookings = () => {
  const [searchForm] = Form.useForm();
  const [bookings, setBookings] = useState([]);
  const [selectedUser, setSelectedBooking] = useState(null);
  const [showUserForm, setShowBookingForm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  //const token = localStorage.getItem('token');

  const onSearch = async (values) => {
    console.log(values);
    const params = new URLSearchParams();
    setShowSpinner(true);

    (values.prefix) && params.append('prefix', values.prefix);
    (values.serial) && params.append('serial', values.serial);
    (values.datefrom) && params.append('datefrom', values.datefrom);
    (values.dateto) && params.append('dateto', values.dateto);
    console.log(params);

    const token = localStorage.getItem('token')
    await AcsApi.get('/booking/acs/list', {
      params,
      headers: {
        sessionid: token
      }
    })
      .then(response => {
        setShowSpinner(false);
        if (response.status === 200) {
          if (response.data.length === 0) {
            Alert('No results found', 'Please try again with different search criteria', 'info');
          } else {
            setBookings(response.data);
            console.log('Bookings:', response.data);
          }
        } else if (response.status === 204) {
          Alert('No results found', 'Please try again with different search criteria', 'info');
        }
      })
      .catch(error => {
        setShowSpinner(false);
        ApiCatch(error);
      });
  };

  const handleSubmit = (userData) => {
    console.log('Submitted:', userData);
    setSelectedBooking(null);
    searchForm.submit();
  };

  const handleEditBooking = (booking) => {
    setSelectedBooking(booking);
    setShowBookingForm(true);
  };

  const handleBack = () => {
    setSelectedBooking(null);
    setShowBookingForm(false);
  };

  // Function to format date as "Mon-DD-YYYY"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' }).substring(0, 3);
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Define columns for the table
  const columns = [
    {
      title: 'Airwaybill',
      key: 'awb',
      render: (_, record) => `${record.prefix}-${record.number}`
    },
    //{ title: 'Pre', dataIndex: 'prefix', key: 'prefix' },
    //{ title: 'AWB Serial', dataIndex: 'number', key: 'number' },
    {
      title: 'Route',
      key: 'route',
      render: (_, record) => `${record.origin} - ${record.destination}`
    },
    //{ title: 'Origin', dataIndex: 'origin', key: 'origin' },
    //{ title: 'Dest', dataIndex: 'destination', key: 'destination' },
    { title: 'Agent', dataIndex: 'agentCode', key: 'agentCode' },
    {
      title: 'Booking Date',
      dataIndex: 'bookDate',
      key: 'bookDate',
      render: date => formatDate(date)
    },
    { title: 'Pieces', dataIndex: 'pieces', key: 'pieces' },
    { title: 'Weight', dataIndex: 'weight', key: 'weight' },
    //{ title: 'Agents', dataIndex: 'agents', key: 'agents', render: types => types.join(', ') },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Button onClick={() => handleEditBooking(record)}>Edit</Button>
    //   ),
    // },
  ];

  const [handleSearchRef, setHandleSearchRef] = useState(() => () => { });

  // Row click handler for mobile
  const onRow = (record) => ({
    onClick: () => {
      // Only trigger on mobile-sized screens; we'll rely on CSS media query to indicate interactivity
      handleEditBooking(record, handleSearchRef);
    },
  });

  return (
    <div style={s.formStyle}>
      <Col span={24}>

        {showUserForm ? (
          <ListBookingsform
            initialUser={selectedUser}
            onSubmit={handleSubmit}
            onBack={handleBack}
          />
        ) : (
          <Card
            title="My Bookings"
            style={s.sectionStyle}
            headStyle={s.cardHeaderStyle}
          >
            <Form form={searchForm} layout="vertical" onFinish={onSearch}>
              <Row>
                <Col xs={7} sm={4} md={2}>
                  <Form.Item
                    label="Prefix"
                    name="prefix"
                  >
                    <Input
                      type="text"
                      style={{ width: '3rem' }}
                    />

                  </Form.Item>
                </Col>
                <Col xs={9} sm={8} md={4}>
                  <Form.Item
                    label="AWB Serial"
                    name="serial"
                    //style={{ width: '4rem' }}
                    rules={[
                      { len: 8, message: 'AWB Number must be 8 digits' }
                    ]}
                    hasFeedback
                  >
                    <Input type="text" />
                  </Form.Item>
                </Col>
                <Col xs={8} sm={12} md={6}>
                  <Form.Item name="agent" label="Agent Code">
                    <Input placeholder="Enter Agent Code" />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={6} md={6}>
                  <Form.Item name="datefrom" label="Date From">
                    <Input style={{ width: 130 }} type='date' max={getCurrentDate()} />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={6} md={6}>
                  <Form.Item name="dateto" label="Date To">
                    <Input style={{ width: 130 }} type='date' max={getCurrentDate()} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">Search</Button>
                  </Form.Item>
                </Col >
              </Row>
            </Form >
            <Table
              //style={{padding: '0.5rem',}} 
              dataSource={bookings}
              columns={columns}
              rowKey="serial"
              className="custom-table" // Ensure this class is applied
              onRow={onRow} // Add row click handler
            />
            {/*<ListBookingsTable dataSource={bookings}/>*/}
          </Card >
        )}
      </Col >
      {
        (showSpinner) && <div><Spinner /></div>
      }
    </div >
  );
};

export default ListBookings;

