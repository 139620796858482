import { ApiLogin } from '../../../api/login';


export const newShipperConsignee = async ( agent, data ) => {

    try {

        const newRegistry = await ApiLogin.post(`acs/participants/v1/save/${agent}`, {data});
        localStorage.removeItem('agent_select');
        return true;
        
    } catch (error) {
        console.log(error);
        return false;
        
    }
}