
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { consultar_reserva } from '../reservas/helpers/consultar_reserva';
import { DetalleReserva, TrackingSummary } from '../reservas/TrackingSummary';

export const ShareTracking = () => {

  const location = useLocation();
  const [ booking, setBooking ] = useState({});

  const params = new URLSearchParams(location.search);
  const serialNumber = params.get('serialNumber');

  const consultar = async(serial) => {
    
     const data = await consultar_reserva(serial);
     setBooking(data);
  }

  useEffect(() => {

    consultar(serialNumber);

  }, []);

  return (
    <>
        {
            (Object.entries(booking).length > 0) && <TrackingSummary booking={booking} />           
        } 
    </>
  )
}


