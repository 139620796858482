import { useContext, useEffect, useState } from 'react';
import { formStyle, sectionStyle, cardHeaderStyle } from '../formStyles';
import { TrackingBody } from './TrackingBody';
import { Button, Form, message, Card } from 'antd';
import { Spinner } from '../ui/Spinner';



export const TrackingMain = (cleanBooking = false) => {


    return (
        <>
            <div style={formStyle}>
                <Card title="Airwaybill Tracking" style={sectionStyle} headStyle={cardHeaderStyle}>
                    <TrackingBody cleanBooking = {cleanBooking}/>
                </Card>
            </div>
        </>
    )
}