import { Form, Input, Button, Row, Col, Card, Select, Modal } from 'antd';
import * as s from '../formStyles';
import React, { useState } from 'react';
import { formStyle, sectionStyle, cardHeaderStyle, cardBodyStyle, reducedMarginFormItem } from '../formStyles';
import '../BookingSearch.css'; // Assuming you'll add some CSS
// import { FaEdit, FaTrash } from 'react-icons/fa'; // Using react-icons for edit/delete icons

export const Home = () => {

    const [searchParams, setSearchParams] = useState({
        customer: '',
        date: '',
        origin: ''
    });
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Replace with your actual API endpoint
            const response = await fetch('https://acsapi-portal.azurewebsites.net/acs/forms/v1/test', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(searchParams)
            });

            const data = await response.json();
            setBookings(data);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (bookingId) => {
        // Implement edit functionality
        console.log(`Edit booking ${bookingId}`);
    };

    const handleDelete = (bookingId) => {
        // Implement delete functionality
        console.log(`Delete booking ${bookingId}`);
    };

    return (
        <div
        style={formStyle}
        >
        <Card  title="Home" 
            style={sectionStyle} 
            headStyle={cardHeaderStyle} 
            bodyStyle={cardBodyStyle}
        >
            <form onSubmit={handleSubmit} className="search-form">
                <div className="form-group">
                    <label>Customer:</label>
                    <input
                        type="text"
                        name="customer"
                        value={searchParams.customer}
                        onChange={handleInputChange}
                        placeholder="Enter customer name"
                    />
                </div>

                <div className="form-group">
                    <label>Date:</label>
                    <input
                        type="date"
                        name="date"
                        value={searchParams.date}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="form-group">
                    <label>Origin:</label>
                    <input
                        type="text"
                        name="origin"
                        value={searchParams.origin}
                        onChange={handleInputChange}
                        placeholder="Enter origin"
                    />
                </div>

                <button type="submit" disabled={loading}>
                    {loading ? 'Searching...' : 'Search Bookings'}
                </button>
            </form>

            <div className="results">
                {bookings.length > 0 ? (
                    bookings.map(booking => (
                        <div key={booking.id} className="booking-item">
                            <div className="booking-details">
                                <div className="line1">
                                    Customer: {booking.customer} | Date: {booking.date}
                                </div>
                                <div className="line2">
                                    Origin: {booking.origin} | Booking ID: {booking.id}
                                </div>
                            </div>
                            <div className="actions">
                                {/* <FaEdit
                                    className="edit-icon"
                                    onClick={() => handleEdit(booking.id)}
                                    title="Edit booking"
                                /> */}
                                {/* <FaTrash
                                    className="delete-icon"
                                    onClick={() => handleDelete(booking.id)}
                                    title="Delete booking"
                                /> */}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No bookings found</p>
                )}
            </div>
        </Card>
        </div>
    );
}