import { TablaReserva } from '../TablaReserva';

export const tracking = ( booking = {}) => {

    const data = booking.booking;

    const { airlinePrefix, chrgweight, destination, events, natureOfGoods, origin, 
        pieces, routingSegments, serialNumber, volume, weight} = data;

    let summary = [];    

    summary = [
        <span>
            <label style={{ minWidth: '160px' }}>
                <strong>Air Waybill:</strong>  
            </label>
            {airlinePrefix}-{serialNumber} 
        </span>,
        <span>           
            <label style={{ minWidth: '160px' }}>
                <strong>Status:</strong>  
            </label>
            {routingSegments[0].actionStatus?.description ?? ''}           
        </span>,
        <div>
            <label style={{ minWidth: '160px' }}>
            <strong>Route:</strong> 
            </label>
            <span>{origin?.code} - {destination?.code}</span>
        </div>,
        <div>           
            <label style={{ minWidth: '160px' }}>
             <strong>Pieces/Weight/Volume:</strong>{' '} 
             </label>
             <span>{pieces}Pcs / {weight?.amount}{weight?.unit} / {volume?.amount}{volume?.unit}</span>          
        </div>,
        <div>           
            <label style={{ minWidth: '160px' }}>
                <strong>Charged Weight:</strong>
            </label>
            {chrgweight?.amount} {chrgweight?.unit}           
        </div>,
        <div>           
            <label style={{ minWidth: '160px' }}>
                <strong>Nature of Goods:</strong> 
            </label>
            <span>{natureOfGoods}</span>           
        </div>,
        <br />,  
        <TablaReserva key={1} data={events[0]}/>,      
             
    ]

    return summary;
}