import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Card, Select, Modal, List, Checkbox } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
//import StyledCard from '../FormStyles';
//import styles from '../formstyles.css'; // Import your CSS module
import { formStyle, sectionStyle, cardHeaderStyle, reducedMarginFormItem } from '../formStyles';

const { Option } = Select;

export const FormTestingList = () => {
  const [form] = Form.useForm();
  const [flightModalVisible, setFlightModalVisible] = useState(false);
  const [selectedEmployees, setSelectedFlights] = useState([]);

  // Mock data for potential employees
  const potentialFlights = [
    { id: 1, route: 'BOS-SJU', flightnr: 'B6-134', date: '02-10-2025', time: '10:00' },
    { id: 2, route: 'BOS-SJU', flightnr: 'B6-234', date: '02-10-2025', time: '11:00'  },
    { id: 3, route: 'BOS-FLL,FLL-SJU', flightnr: 'B6-2134', date: '02-10-2025', time: '14:00' },
  ];

  // Functions for Flight Modal
  const showEmployeeModal = () => {
    setFlightModalVisible(true);
  };

  const handleFlightOk = () => {
    // Add selected flights to the form
    form.setFieldsValue({ flights: [...(form.getFieldValue('flights') || []), ...selectedEmployees] });
    setSelectedFlights([]);
    setFlightModalVisible(false);
  };

  const handleFlightCancel = () => {
    setSelectedFlights([]);
    setFlightModalVisible(false);
  };

  const onFlightCheck = (flights) => {
    setSelectedFlights(prev => {
      const isChecked = prev.some(e => e.id === flights.id);
      if (isChecked) {
        return prev.filter(e => e.id !== flights.id);
      } else {
        return [...prev, flights];
      }
    });
  };

  return (
    <>
      <Card
        title="Flights 2"
        style={sectionStyle}
        headStyle={cardHeaderStyle}
        extra={
          <Button onClick={showEmployeeModal} type="primary">
            Add
          </Button>
        }
      >
        <Row gutter={[12, 8]}>
          <Form.Item className="flight-list">
            {/*<Form.Item label="Employees">*/}
            <div style={{ marginBottom: '10px', fontWeight: 'bold', width: '100%' }}>
              <Row gutter={8}>
                <Col span={8}>
                  <Row>
                    <Col sm={24} md={12}>Flight Nr</Col>
                    <Col sm={24} md={12}>Route</Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col sm={24} md={12}>Dep Date</Col>
                    <Col sm={24} md={12}>Dep Time</Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col sm={24} md={12}>Pieces</Col>
                    <Col sm={24} md={12}>Weight</Col>
                  </Row>
                </Col>
                <Col span={2}></Col> {/* Space for the remove button */}

              </Row>
            </div>
            <Form.List name="flights">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key} style={{ marginBottom: 8 }}>
                      <Col span={8}>
                        <Row>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'flightnr']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Flight" />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'route']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Route" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={8}>
                        <Row>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'date']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Date" />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'time']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Time" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6}>
                        <Row>
                          <Col sm={24} md={10}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'pieces']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input placeholder="Pieces" />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={14}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'weight']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input placeholder="weight" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={2} className='remove-icon-wrapper'>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          style={{ marginTop: 8 }}
                        />
                      </Col>
                    </Row>
                  ))}
                  {/*<Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Employee
                    </Button>
                  </Form.Item>
                  <Button onClick={showEmployeeModal} type="primary" style={{ marginBottom: 8 }}>
                    Add Employees
                  </Button>*/}
                </>
              )}
            </Form.List>
          </Form.Item>
        </Row>
      </Card>

      {/* Employee Selection Modal */}
      <Modal
        title="Select Flights 2"
        open={flightModalVisible}
        onOk={handleFlightOk}
        onCancel={handleFlightCancel}
        width={600}
      >
        <List
          itemLayout="horizontal"
          dataSource={potentialFlights}
          renderItem={flight => (
            <List.Item>
              <Checkbox
                checked={selectedEmployees.some(e => e.id === flight.id)}
                onChange={() => onFlightCheck(flight)}
              >
                {`${flight.route} - ${flight.flightnr}`}
              </Checkbox>
            </List.Item>
          )}
        />
      </Modal>
      </>
  );
};

export default FormTestingList;