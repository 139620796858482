import React, { useState } from 'react';
import { Button, Drawer, Select, Space } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { TrackingMain } from '../reservas/TrackingMain'

const { Option } = Select;


export const TrackingHome = () => {

  const [open, setOpen] = useState(false);  
  const [cleanBooking, setcleanBooking] = useState(false);  

  const Track = () => {
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
    setcleanBooking(true);
  };

  return (
    <>
    <div className='contenedor_btn'>

      <Button 
          type="link" 
          style={{backgroundColor: '#1D2758', color: 'white'}}
          onClick={Track}                    
          >

            <EnvironmentOutlined />

            <span>
              Track My Shipment
            </span>
      </Button>
    </div>

      <Drawer
        title= { 
          <p 
            style={{fontSize: 22}}
          >
            Track My Shipment 
            <i 
              className="fa-solid fa-plane fa-xl" 
              style={{
                color: '#1D2758', 
                paddingLeft: 10
              }}
            >
            </i>
          </p>
        }
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button 
              onClick={onClose} 
              style={{backgroundColor: '#1D2758', color: 'white'}}
            >
              Cancel
            </Button>
          </Space>
        }
      >

       <TrackingMain cleanBooking />{/*}{*/}

      </Drawer>
    </>
  );
}

 
