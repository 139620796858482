import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  
import { Button, Popconfirm, Form, Input } from 'antd';
import { Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { ReservaContext } from '../reservas/context/reservaContext';
import { TablaVuelosReserva } from '../reservas/TablaVuelosReserva';
import { AvailabilityObj } from '../reservas/helpers/VuelosDisponibles';
import { Flight } from '../vuelos/Flight';
import { getCurrentDate } from '../reservas/helpers/fechaActual';
import { MsgAvailability } from '../ui/MsgAvailability';
import { CancelBooking, UpdateBooking } from '../reservas/helpers/MethodsBooking';
import { Alert } from '../ui/Alert';
import * as s from '../formStyles';

const { Title } = Typography;

export const ListBookingsform = () => {

    const location = useLocation();
    const navigate = useNavigate(); 

    const { r = ''} = queryString.parse(location.search);
    const token = localStorage.getItem('token');
    
    const [ reserva_init, setReserva_init ] = useContext(ReservaContext);
    const [ deshabilitado, setDeshabilitado] = useState(true);

    const [ listado, setListado] = useState([]);
    const [ btnSearchFligths, setBtnSearchFligths] = useState(false);  

    const [ btnEnviarReserva, setBtnEnviarReserva] = useState(false);  
   

    useEffect( () => {
        
        if(!r){
            navigate('/formulario');
            return
        }
    }, []);

    useEffect( () => {}, [btnSearchFligths]);
    
    const bookingObj = JSON.parse(r);     

    const { id, agent, destination, airWaybill, origin, productCode, 
            shipper, pieces, volume, weight, routing, natureOfGoods, eTag } = bookingObj;             
            
    useEffect( () => {

        localStorage.setItem('etag', eTag);

        return () => {
            localStorage.removeItem('etag');
        }

    }, [eTag]);
  
    const onFinish = async (values) => { 

        const { destination, origin } = values;   
        const consultarDisponibilidad = await AvailabilityObj(agent?.accountNumber, destination, origin, weight?.amount, getCurrentDate(), token, productCode);
        
        setListado(consultarDisponibilidad);  
        
        if(listado.length === 0){
            setBtnSearchFligths(true);
        }
       
        setReserva_init({
            agentAccountNumber: agent.accountNumber,
            airWaybill: {
                prefix: '279',
                referenceType: 'AIR WAYBILL',
                serial: values.serial
            },
            destinationAirportCode: values.destination,
            natureOfGoods: values.natureOfGoods,
            originAirportCode: values.origin,
            pieces: values.totalPieces,
            segments: [],
            weight:{ amount: values.totalWeight, unit: 'LB' }
        });
        
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const actualizarBooking = async () => { 
                        
        const actualizada = await UpdateBooking(id, reserva_init);

        if(actualizada){

            setReserva_init({ 
                agentAccountNumber: '',
                airWaybill: {
                    prefix: "279",
                    referenceType: 'AIR WAYBILL'
                },
                destinationAirportCode: '',
                natureOfGoods: '',
                originAirportCode: '',
                pieces: '',
                segments: [],
                weight:{ amount: '', unit: 'LB' }
            }); 

            localStorage.setItem('send', 'ok'); 
            localStorage.removeItem('etag');                   

            navigate('/formulario');  

        }       
    }
      
    const CancelarBooking = async (id) => {
          
        const cancelada = await CancelBooking(id);
    
        if(cancelada){
            localStorage.removeItem('etag');
            navigate('/formulario');
        }
        
        else{

            Alert('Error', 'Algo salio mal', 'error');

            setTimeout(() => {
                navigate('/formulario');
            }, 3000);
        }      
    }

    return(
        <>
            <Title>Booking</Title>

            <br />

            <Form 
                name="basic"               
                layout='inline'
                size='small'
                disabled={deshabilitado}
                maxWidth = {1000}
                fields={[
                    {
                      name: ["airlinePrefix"],
                      value: airWaybill?.prefix,
                    },
                    {
                      name: ["serial"],
                      value: airWaybill?.serial,
                    },
                    {
                      name: ["agent"],
                      value: agent?.name,
                    },
                    {
                      name: ["contact"],
                      value: shipper?.contact?.phoneNumber,
                    },
                    {
                      name: ["shipper"], 
                      value: shipper?.name,
                    },
                    {
                      name: ["origin"],  
                      value: origin?.code,
                    },
                    {
                      name: ["destination"],    
                      value: destination?.code
                    },
                    {
                      name: ["natureOfGoods"],    
                      value: natureOfGoods
                    },
                    {
                      name: ["totalPieces"],   
                      value: pieces 
                    },
                    {
                      name: ["totalVolume"],  
                      value: volume?.amount   
                    },
                    {
                      name: ["totalWeight"],   
                      value: weight?.amount
                    },
                  ]}
                labelCol={{
                span: 7,
                }}
                wrapperCol={{
                span: 16, 
                }}
                style={{
                    maxWidth: 1000,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >

            <Form.Item
                label="Awb"
                name="airlinePrefix"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 6,
                }}
            >
                <Input disabled />

            </Form.Item>

            <Form.Item
                label="Number"
                name="serial"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 9,
                }}
                
            >
                <Input
                    type='text'
                    defaultValue={airWaybill?.serial}
                />
            </Form.Item>

            <Form.Item
                label="Agent"
                name="agent"
                style={{ marginBottom: '1rem'}}               
            >
                <Input type='text' />
            </Form.Item>

            <Form.Item
                label="Shipper"
                name="shipper"
                wrapperCol={{
                    span: 10,
                }} 
                style={{ marginBottom: '1rem'}}
                
                >
                <Input type='text' />
            </Form.Item>

            <Form.Item
                label="Phone"
                name="contact"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 11,
                }}               
            >
                <Input type='text' />
            </Form.Item>


            <Form.Item
                label="Origin"
                name="origin"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 6,
                }}               
            >
                <Input type='text' />
            </Form.Item>

            <Form.Item
                label="Dest"
                name="destination"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 6,
                }}               
            >
                <Input type='text' />
            </Form.Item>

            <Form.Item
                label="Nature of Goods"
                name="natureOfGoods"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 10,
                }}               
            >
                <Input type='text' />
            </Form.Item>

            <Form.Item
                label="Pieces"
                name="totalPieces"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 6,
                }}               
            >
                <Input type='number' />
            </Form.Item>

            <Form.Item
                label="Weight (LB)"
                name="totalWeight"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 10,
                }}               
            >
                <Input type='number' />
            </Form.Item>

            <Form.Item
                label="Vol"
                name="totalVolume"
                style={{ marginBottom: '1rem'}}
                wrapperCol={{
                    span: 8,
                }}               
            >
                <Input type='number' />
            </Form.Item>            

            {
                (!deshabilitado) &&                   
                
                   <Button type="primary" size="middle" htmlType="submit">  
                        Search Fligths
                  </Button>
            }

           
            
        </Form>        

           {/*  Mostrar los vuelos de la reserva */}
        <br />

        {  
            (listado.length === 0 && btnSearchFligths) && 
                <MsgAvailability  />
        }

        <div >
            {
                    
                ( listado && listado.length > 0) 
                
                    ? 
                    <>
                        <Flight 
                            listado={listado} 
                            reserva={reserva_init}    
                            btnEnviarReserva={btnEnviarReserva}
                            setBtnEnviarReserva={setBtnEnviarReserva}                                          
                        />  

                        
                        <Button
                            style={{backgroundColor: '#5cb85c', color: 'white'}}                            
                            onClick={actualizarBooking}                                      
                        >
                            Send
                        </Button>

                    </>
                        
                    : 

                    <>
                        <TablaVuelosReserva route={routing} />            

                        <Popconfirm
                            title="Cancel booking"
                            description="¿Are you sure to cancel this booking?"
                            onConfirm={() => CancelarBooking(id)}
                            icon={
                            <QuestionCircleOutlined
                                style={{
                                color: 'red',
                                }}
                            />
                            }
                        >

                            <Button 
                                danger
                                style={{marginLeft: '80%'}}
                            >
                                Cancel Booking
                            </Button>
    
                        </Popconfirm>
    
                        <Button 
                            type="primary"  
                            size="middle" 
                            style={{marginLeft: 5}}
                            onClick={ () => setDeshabilitado(!deshabilitado)}
                        >
                        {
                            (deshabilitado) ? 'Edit' : 'Cancel'
                        }
                        </Button>   
                    </>        
            }         
            </div>          
        </>
    )
}
