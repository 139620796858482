import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography, Menu } from 'antd';
import { useWindow } from '../reservas/hooks/useWindow';
import { ApiContext } from '../../context/ApiContext';
import { ApiLogin } from '../../api/login';
import { Spinner } from '../ui/Spinner';

const items = [
  {
    key: '1',
    label: 'Logout',
  },
];

export const MenuUser = () => {

  const [ auth, guardarAuth ] = useContext(ApiContext);
  const { username, token } = auth;

  const [showSpineer, setShowSpinner] = useState(false); 

  const navigate = useNavigate();
  const ancho = useWindow();

  const handleMenuClick = async (e) => {

    if (e.key === '1') {

      setShowSpinner(true);
     
        try {

           const respuesta = await ApiLogin.post('Authenticate/logout', {
                username,
                token
          });
              
          const { ok } = respuesta.data;
              
          if(ok){
    
            guardarAuth({
              token: '',
              auth: false,
              agents: null,
              username: '',
              destinations:'',
              origins:'',
              products:'',
              shc:'',
            });

            setShowSpinner(true);

            localStorage.clear();            
            navigate('/');
          } 
          
        } catch (error) {
          console.log(error);    
          setShowSpinner(false);
        }
      
    }
  };

  return (
    <>
    <Dropdown 
      overlay={<Menu items={items} onClick={handleMenuClick} />} 
      trigger={['click']} // Defino el tipo de activacion
    >

      <div style={{ margin: `0.2rem 1.2rem 0.2rem ${ancho > 768 ? '85%' : '50%'} `  }}>

        <Space direction="vertical" >
          <Typography.Text style={{ fontSize: '1.05rem' }}>
            {username ? username : ''} <UserOutlined />
          </Typography.Text>    
        </Space>

      </div>

    </Dropdown>

      { 
        (showSpineer) && <Spinner />
      }
      
    </>    
  );
};

