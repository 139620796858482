
import React, { useMemo, useState } from 'react';
import { List } from 'antd';
import { tracking } from './helpers/tracking';
import { TrackingEvents } from './TrackingEvents';


export const TrackingSummary = (booking) => { 

  const summary = useMemo( () => tracking(booking), [booking]);
  const events = Object.values(booking.booking.events);  

  const [ mostrar, setMostrar ] = useState(false);
 
  const mostrarTabla = () => {
      setMostrar(!mostrar); 
  }
 
    return(
        <>
          <br/>
            <List
              style={{
                maxWidth: 1000,
              }}
              bordered
              header={<div><strong style={{fontSize: 16}}>Summary</strong></div>}

              dataSource={summary}
              renderItem={(item) => (
                  <List.Item style={{paddingTop: 3, paddingBottom: 3}}>              
                  {item}
                  </List.Item>
              )}

              footer={ 
                <button 
                  className='btn btn-success' 
                  onClick={mostrarTabla} 
                  style={{backgroundColor: '#1D2758', color: 'white', padding: '2px 5px'}}
                >
                  Show Details
                </button>                        
              }                
            />

            {
              (mostrar) && <TrackingEvents events={events} />     
            }  
      </>
    )
}