import axios from 'axios';

export const ApiLogin = axios.create({
    baseURL: 'https://acsapi-portal.azurewebsites.net/',  
    headers: {
        "Content-Type": "application/json"
    }
}); 

export const ApiOlvide = axios.create({
    baseURL: 'https://api-portal.aeronexcargo.com/Authenticate/'
});
