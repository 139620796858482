import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Card, Select, Modal } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Spinner } from '../ui/Spinner';
import * as s from '../formStyles';
//import { formStyle, sectionStyle, cardHeaderStyle, reducedMarginFormItem } from '../formStyles';
import AcsApi from '../../api/AcsApi';
//import axios from 'axios';
import '../styles.css'

const { Option } = Select;

export const UserSettingsForm = ({ initialUser, onSubmit, onBack }) => {
  const [form] = Form.useForm();
  const [customerName, setCustomerName] = useState('');
  //const [isModalVisible, setIsModalVisible] = useState(false);
  const [newUser, setNewUser] = useState({ code: '', name: '' });
  const [userId, setUserId] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (initialUser) {
      //userId = initialUser.username;
      setUserId(initialUser.username);
      console.log('Called from Table: ', initialUser.username);
      handleSearch(initialUser.username);
      //form.setFieldsValue(initialUser);
      setIsEditMode(true);
    }
  }, [initialUser, form]);

  const [loading, setLoading] = useState(false);

  // Function to fetch data based on ID
  //const fetchData = async (id) => {
  const handleSearch = async (Id = userId) => {
    console.log('handle Id: ', Id);
    if (!Id) return; // Don't search if ID is empty
    setLoading(true);
    try {
      console.log(`User Id: ${Id}`)

              const response = await AcsApi.get(`/acs/users/v1/get/${Id}`);
              console.log(response);

      if (response.data) {
        // Fill form fields with the fetched data
        form.setFieldsValue({
          company: response.data?.data?.company ?? '',
          name: response.data?.data?.name ?? '',
          lastname: response.data?.data?.lastname ?? '',
          address: response.data?.data?.address ?? '',
          city: response.data?.data?.city ?? null,
          email: response.data?.data?.email ?? null,
          phone: response.data?.data?.phone,
          agents: response.data?.dataExt?.agents,
          gsas: response.data?.dataExt?.gsas,
        });
        setIsEditMode(true);
      }
      else {
        console.log('No data found for the given ID');
        // Optionally, reset fields or show message
        form.resetFields();
        setIsEditMode(false);
      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
      // Optionally, reset fields or show error message
      form.setFieldsValue({ name: '', email: '' }); // Reset fields on error
    }
    finally {
      setLoading(false);

    }
  };

  // Handle key events for search
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault(); // Prevent default Tab behavior
      handleSearch();
    }
  };

  // Handle blur event for search
  // Perform search when input loses focus
  const handleBlur = () => {
    console.log('Blur Event:', userId);
    handleSearch();
  };

  const onFinish = async (values) => {
    console.log('Saving User:', userId);
    const basicData = {
      ...values,
      //id: userId //: Date.now().toString(),
    };
    const extData = {
      agents: values.agents,
      gsas: values.gsas || [],
      userType: values.userType || '',
      environment: values.environment || 'T'
    }
    const userData = {
      username: userId,
      Data: basicData,
      DataExt: extData,
    }

    Modal.confirm({
      title: isEditMode ? 'Confirm User Update' : 'Confirm New User',
      content: (
        <div>
          <p>Please confirm the booking details:</p>
          <p><strong>ID:</strong> {userId}</p>
          <p><strong>User:</strong> {basicData.name}</p>
          <p><strong>E-Mail:</strong> {basicData.email}</p>
          <p><strong>Agents:</strong> {extData.agents.join(', ')}</p>
        </div>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      async onOk() {
        console.log('Booking data:', userData);
        setLoading(true);
        try {
          if (isEditMode) {
            console.log('Updating booking:', userData);

            // Post to endpoint Saving Data
            const response = await AcsApi.post(`/acs/users/v1/saveext/${userId}`, userData);

            // Optionally, you might want to show a success message to the user
            console.log('User saved successfully',response);

          } else {
            console.log('Creating new user:', userData);
          }

          form.resetFields();
          setUserId('');
          setIsEditMode(false);
        } catch (error) {
          console.error('Error saving data:', error);
          // Handle error - show error message to user, etc.
        } finally {
          form.resetFields();
          setUserId('');
          setIsEditMode(false);
          setLoading(false);
        }

      },
      onCancel() {
        console.log('User submission cancelled');
      },
    });

    console.log('Form values:', values);
  };

  // Reset form when clicking new booking
  const handleNewUser = () => {
    form.resetFields();
    setUserId('');
    setIsEditMode(false);
  };

  return (
    <>
      <Card
        title="User Details"
        style={s.sectionStyle}
        headStyle={s.cardHeaderStyle}
      >
        <Row gutter={[16, 8]}>
          <Col xs={24} sm={12} md={12}>

            <Input placeholder="Enter ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              //onPressEnter={handleSearch} // Handle Enter key
              //onKeyDown={handleKeyDown} // Handle Enter or Tab key
              onBlur={handleBlur} // Handle blur event (loses Focus)
            //prefix={<SearchOutlined />}
            />

          </Col>
          <Col xs={24} sm={12} md={12}>
            <Button type="primary" onClick={handleSearch}>Search</Button>
            <Button onClick={handleNewUser}>New User</Button>
            <Button onClick={onBack}>Back to Search</Button>
          </Col>
        </Row>
      </Card>

      {/* Section 1: General Information */}
      <Form form={form}
        name="responsiveForm"
        onFinish={onFinish}
        layout="vertical"
      //style={s.formStyle}
      >
        <Card
          title="Assigned Agents / GSAs"
          style={s.sectionStyle}
          headStyle={s.cardHeaderStyle}
        >

          <Row gutter={[16, 8]}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Name"
                name="name"
                style={s.reducedMarginFormItem}
                rules={[{ required: true, message: 'Name is required!' }]}
              >
                <Input //disabled
                  value={customerName}
                  placeholder="Name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                style={s.reducedMarginFormItem}
              >
                <Input
                  value={customerName}
                  //disabled
                  placeholder="Address auto-fill"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="E-Mail"
                name="email"
                style={s.reducedMarginFormItem}
                rules={[{ required: true, message: 'Email is required!' }]}
              >
                <Input
                  value={customerName}
                  //disabled
                  placeholder="E-Mail"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Company"
                name="company"
                style={s.reducedMarginFormItem}
              >
                <Input
                  value={customerName}
                  //disabled
                  placeholder="Company"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Address"
                name="address"
                style={s.reducedMarginFormItem}
              //rules={[{ required: true, message: 'City is required!' }]}
              >
                <Input
                  value={customerName}
                  //disabled
                  placeholder="City auto-fill"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="City"
                name="city"
                style={s.reducedMarginFormItem}
              //rules={[{ required: true, message: 'City is required!' }]}
              >
                <Input
                  value={customerName}
                  //disabled
                  placeholder="City auto-fill"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Section 2: Agents / GSAs */}
        <Card
          title="Assigned Agents / GSAs"
          style={s.sectionStyle}
          headStyle={s.cardHeaderStyle}
        >

          <Row gutter={[16, 8]}>
            <Col xs={24} sm={24} md={12}>
              <Row gutter={[12, 8]}>
                <Form.Item className="flight-list">
                  {/*<Form.Item label="Employees">*/}
                  <div style={{ marginBottom: '10px', fontWeight: 'bold', width: '100%' }}>
                    <Row gutter={8}>
                      <Col span={8}>Agent Id</Col>
                      <Col span={14}>Name</Col>
                      <Col span={2}></Col> {/* Space for the remove button */}
                    </Row>
                  </div>
                  <Form.List name="agents">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row gutter={4} key={field.key} style={{ marginBottom: 2 }}>
                            <Col span={8}>
                              <Form.Item className="listitem"
                                {...field}
                                name={[field.name, 'id']}
                              //rules={[{ required: true, message: 'Required' }]}
                              >
                                <Input placeholder="Agent Id" />
                              </Form.Item>
                            </Col>

                            <Col span={14}>
                              <Form.Item className="listitem"
                                {...field}
                                name={[field.name, 'name']}
                              //rules={[{ required: true, message: 'Required' }]}
                              >
                                <Input placeholder="Agent Name" />
                              </Form.Item>
                            </Col>

                            <Col span={2} className='remove-icon-wrapper'>
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                                style={{ marginTop: 8 }}
                              />
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Agent
                          </Button>
                        </Form.Item>
                        {/*<Button onClick={showEmployeeModal} type="primary" style={{ marginBottom: 8 }}>
                    Add Employees
                  </Button>*/}
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Row gutter={[12, 8]} style={{ width: '100%' }}>
                <Form.Item className="flight-list">
                  {/*<Form.Item label="Employees">*/}
                  <div style={{ marginBottom: '10px', fontWeight: 'bold', width: '100%' }}>
                    <Row gutter={8} style={{ width: '100%' }}>
                      <Col span={8}>GSA Id</Col>
                      <Col span={14}>GSA Name</Col>
                      <Col span={2}></Col> {/* Space for the remove button */}
                    </Row>
                  </div>
                  <Form.List name="gsas">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row gutter={4} key={field.key} style={{ marginBottom: 2 }}>
                            <Col span={8}>
                              <Form.Item className="listitem"
                                {...field}
                                name={[field.name, 'id']}
                              //rules={[{ required: true, message: 'Required' }]}
                              >
                                <Input placeholder="GSA Id" />
                              </Form.Item>
                            </Col>

                            <Col span={14}>
                              <Form.Item className="listitem"
                                {...field}
                                name={[field.name, 'name']}
                              //rules={[{ required: true, message: 'Required' }]}
                              >
                                <Input placeholder="GSA Name" />
                              </Form.Item>
                            </Col>

                            <Col span={2} className='remove-icon-wrapper'>
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                                style={{ marginTop: 8 }}
                              />
                            </Col>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Agent
                          </Button>
                        </Form.Item>
                        {/*<Button onClick={showEmployeeModal} type="primary" style={{ marginBottom: 8 }}>
                    Add Employees
                  </Button>*/}
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Card>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      {
        (loading) && <div><Spinner /></div>
      }
    </>
  );
};
export default UserSettingsForm;