
import { BtnSelect } from '../ButtonSelect';

/*
    funcion para recorrer el objeto inicial y dar formato 
    a los datos que voy a retornar (para renderizar despues)
*/

export const formatoVuelos = ( listado = [], reserva = {}, btnEnviarReserva , setBtnEnviarReserva) => {

    // tomo los segments ahi se encuentra informacion de los vuelos
    let items = [];
    let segment = []; 
  
    listado.forEach( (vuelo, i) => {

        let objeto = {
            key:  '',
            label:  '',
            children:  '',
            vuelo_completo: {}
        }

        // segments contiene toda la info del vuelo que despues envio al crear la reserva
        segment = vuelo.segments
        
        segment.forEach( e => {  

            objeto.key = e.transportMeans.id
            objeto.vuelo_completo = e    

            objeto.label = <div className='contendorFlights'> 

                                <div className='seccionFlight'>                        
                                    <i class="fa-solid fa-plane-departure fa-xl"></i>
                                </div>

                                <div className='seccionFlight'>
                                    <p>
                                        <strong> 
                                            ORIGIN/DESTINATION 
                                        </strong>  <br/> {e.onload.code}-{e.offload.code}
                                        &nbsp;
                                    </p>
                                 </div>

                                 <div className='seccionFlight'>
                                    <p>
                                        <strong>
                                            FLIGHT
                                        </strong>
                                        <br/>
                                    <span>
                                        {e.transportMeans.reference}
                                    </span>
                                    </p>
                                 </div>

                                 <div className='seccionFlight'>
                                    <p>
                                        <strong>
                                            SCHEDULE
                                        </strong>
                                        <br/>
                                    <span>
                                        {e.transportMeans.scheduledDeparture} <br />                                
                                        {e.transportMeans.scheduledArrival}    
                                    </span>
                                    </p>
                                 </div>
                            </div> 

                objeto.children =   <BtnSelect  
                                        key={e.transportMeans.id} 
                                        segment={ objeto.vuelo_completo } 
                                        reserva={reserva}  
                                        btnEnviarReserva={btnEnviarReserva}
                                        setBtnEnviarReserva={setBtnEnviarReserva}                                          
                                    />       
                                                                     
        });
        
        items.push(objeto);       
        
    });

    return items;
   
}