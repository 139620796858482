import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
//import { BtnEnviarReserva } from './BtnEnviarReserva';
import { ReservaContext } from './context/reservaContext';
//import { AvailabilityObj } from './helpers/VuelosDisponibles';
//import { ErrorAvailabilityFlight } from './ErrorAvailabilityFlight';
import { FormBooking } from './NewBooking-Form';
import { BookingShipper } from './NewBooking-Shipper';
import { BookingConsignee } from './NewBooking-Consignee';
import { FlightList } from './NewBooking-SelectFlights';
//import { ModalFlights } from './NewBooking-ModalFlights';
import { Button, Form, message, Divider } from 'antd';
import { formStyle } from '../formStyles';
import { ApiContext } from '../../context/ApiContext';
//import { Bookings } from '../../api/Bookings';
import { Spinner } from '../ui/Spinner';
import { Alert } from '../ui/Alert';
import { useNavigate } from 'react-router-dom';
import {AcsApi, ApiCatch }from '../../api/AcsApi';

const formatFormData = (values) => {
    // Format the form data for the API


    return {


        agentAccountNumber: values.agent,
        airWaybill: {
            prefix: values.prefix ? values.prefix : '279',
            referenceType: 'AIR WAYBILL',
            serial: values.serial
        },
        destinationAirportCode: values.destinationAirportCode,
        natureOfGoods: values.natureOfGoods,
        originAirportCode: values.originAirportCode,
        pieces: Number(values.pieces) || 0,
        weight: { amount: Number(values.weight) || 0, unit: 'LB' },
        productCode: values.product,
        salesAreaCode: 'MIA',

        segments: values.flights ? values.flights.flatMap(flight =>
            flight.segments.map(s => ({
                destinationAirportCode: s.offload.code,
                originAirportCode: s.onload.code,
                pieces: Number(flight.pieces) || 0,
                weight: {
                    amount: Number(flight.weight) || 0,
                    unit: 'LB'
                },
                spaceAllocationCode: 'KK',
                transportMeans: {
                    carrierCode: s.transportMeans.carrier.code,
                    date: s.transportMeans.date,
                    number: s.transportMeans.transportNumber
                },
            }))
        ) : [],
    };
};

export const NewBooking = () => {

    const token = localStorage.getItem('token');

    const [listado, setlistado] = useState([]);
    //const [btnEnviarReserva, setBtnEnviarReserva] = useState(false);
    //const [AgentSelect, setAgentSelect] = useState('');

    const [disableButton, setDisableButton] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [NotFligths, setNotFligths] = useState(false);

    const [reserva_init, setReserva_init] = useContext(ReservaContext);
    const [auth, guardarAuth] = useContext(ApiContext);

    const navigate = useNavigate();

    const serializeForm = (values) => {
        const formData = form.getFieldsValue();
        // Log or do something with formData
        console.log('Serialized Form Data:', values);
        // Example: Convert to JSON string
        const jsonData = JSON.stringify(values);
        console.log('JSON String:', jsonData);
        return 'Serialized:' + jsonData;
    };

    const [form] = Form.useForm();
    const [personalInfoFilled, setPersonalInfoFilled] = React.useState(false);

    const onFinish = async (values) => {

        await Alert('You are about to confirm the booking',
            'AWB ' + reserva_init.airWaybill.prefix + '-' + reserva_init.airWaybill.serial +
            '\nfor ' + reserva_init.agentAccountNumber,
            'success');

        setShowSpinner(true);
        const output = formatFormData(values);
        console.log('Output values:', output);

        await AcsApi.post('/booking/acs/bookings', output, {//})
            // await Bookings.post('/acs/bookings', output, { //reserva_init, {
            headers: {
                sessionid: token,
            }
        })
            .then(response => {
                console.log('Success:', response.data);
                Alert('The Booking is confirmed',
                    'AWB ' + response.data.airWaybill.prefix + '-' + response.data.airWaybill.serial +
                    '\nfor ' + response.data.agent.accountNumber,
                    'success');

                setReserva_init({
                    agentAccountNumber: '',
                    airWaybill: {
                        prefix: "279",
                        referenceType: 'AIR WAYBILL'
                    },
                    destinationAirportCode: '',
                    natureOfGoods: '',
                    originAirportCode: '',
                    pieces: '',
                    segments: [],
                    weight: { amount: '', unit: 'LB' },
                    productCode: '',
                    salesAreaCode: ''
                });
                localStorage.setItem('send', 'ok');
                setShowSpinner(false);
                navigate('/form');
            })
            .catch(error => {
                setShowSpinner(false);
                ApiCatch(error);
            });
    }

    //Enables Add Flight field once all requirements are met
    const checkPersonalInfoFilled = () => {
        const values = form.getFieldsValue([
            'agent',
            'product',
            'originAirportCode',
            'destinationAirportCode',
            'weight',
            'departureOn'
        ]);

        console.log('Serialized Form Data:', form.getFieldsValue());
        const allFilled = values.agent &&
            values.product &&
            values.originAirportCode &&
            values.destinationAirportCode &&
            values.weight &&
            values.departureOn;
        setPersonalInfoFilled(allFilled);
    };

    return (
        <>
            <Form
                name="form"
                layout="vertical"
                style={formStyle}
                //labelCol={{ span: 12 }}
                //wrapperCol={{ span: 12 }}
                onFinish={onFinish}
                colon={false}
                form={form}
                onLoadStart={console.log('Auth values:', auth)}
            >
                <Divider tex>{/* className='back' */}
                    <Link to='/form'>Back</Link>
                    <strong> New Booking </strong>
                </Divider>

                <p style={{ padding: '0rem 0.8rem', fontWeight: '600' }}>Enter the information to create a new booking</p>
                <FormBooking onFieldsChange={checkPersonalInfoFilled} listado={listado} />
                <BookingShipper form={form}/>
                <BookingConsignee form={form}/>
                <FlightList form={form} list={listado} personalInfoFilled={personalInfoFilled} />

                <div className='ContenedorSearchFligths'>
                    <Form.Item >
                        <Button
                            id='btn_searchFlights'
                            type='primary'
                            htmlType='submit'
                            style={{
                                backgroundColor: '#1D2758',
                                color: 'white',
                                margin: '0.5rem 0rem',
                            }}
                            disabled={disableButton}
                        >
                            Confirm Booking
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            {
                (showSpinner) && <div><Spinner /></div>
            }
            {/*
                (listado && listado.length > 0) ?

                    <ModalFlights
                        listado={listado}
                        reserva={reserva_init}
                        btnEnviarReserva={btnEnviarReserva}
                        setBtnEnviarReserva={setBtnEnviarReserva}
                        AgentSelect={AgentSelect}
                    />

                    : (!showSpinner && !listado) && <ErrorAvailabilityFlight />
            }

            {
                (btnEnviarReserva) && <BtnEnviarReserva />
            */}

            {/* </Form> */}
        </>
    )
}