import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Divider, Card, Select, Modal, List, Checkbox } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Alert } from '../ui/Alert';
//import StyledCard from '../FormStyles';
//import styles from '../formstyles.css'; // Import your CSS module
import { formStyle, sectionStyle, cardHeaderStyle, reducedMarginFormItem } from '../formStyles';
import FormTestingList from './FormTestingList';
import FlightsList from './FormTestingAppointments';
import FormTestingContactInfo from './FormTestingContactInfo';

const { Option } = Select;

export const FormTesting = () => {
  const [form] = Form.useForm();
  const [customerName, setCustomerName] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [newCustomer, setNewCustomer] = useState({ code: '', name: '' });
  const [flightModalVisible, setFlightModalVisible] = useState(false);
  const [selectedFlights, setSelectedFlights] = useState([]);

  // Sample data for customer codes and names
  const [customers, setCustomers] = useState([
    { code: 'CUST001', name: 'John Doe', city: 'New York', address: '123 Main St' },
    { code: 'CUST002', name: 'Jane Smith', city: 'Boston', address: '456 Elm St' },
    { code: 'CUST003', name: 'Bob Johnson', city: 'Atlanta', address: '789 Oak St' },
  ]);

  // Mock data for potential flights
  const potentialFlights = [
    { id: 1, route: 'BOS-SJU', flightnr: 'B6-134', date: '02-10-2025', time: '10:00' },
    { id: 2, route: 'BOS-SJU', flightnr: 'B6-234', date: '02-10-2025', time: '11:00'  },
    { id: 3, route: 'BOS-FLL,FLL-SJU', flightnr: 'B6-2134', date: '02-10-2025', time: '14:00' },
  ];

  const onFinish = (values) => {
    Alert('You are about to confirm the booking', 
      'AWB ', 
      'success');
    console.log('Form values:', values);
  };

  const handleCustomerCodeChange = (value) => {
    const selectedCustomer = customers.find(customer => customer.code === value);
    if (selectedCustomer) {
      setCustomerName(selectedCustomer.name);
      // Set the customer name in the form
      form.setFieldsValue({ shipperName: selectedCustomer.name, address: selectedCustomer.address, city: selectedCustomer.city });
    } else {
      setCustomerName('');
      form.setFieldsValue({ shipperName: '', address: '', city: '' }); // Clear the form fields if no customer is selected
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Add the new customer to the list
    setCustomers(prevCustomers => [...prevCustomers, newCustomer]);
    // Automatically select the new customer in the form
    form.setFieldsValue({
      customerCode: newCustomer.code,
      shipperName: newCustomer.name,
      address: newCustomer.address,
      city: newCustomer.city
    });
    setIsModalVisible(false);
    setNewCustomer({ code: '', name: '' }); // Reset modal form
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewCustomer({ code: '', name: '' }); // Reset modal form
  };

  const onNewCustomerChange = (changedValues, allValues) => {
    setNewCustomer(allValues);
  };

  // Functions for Flight Modal
  const showFlightModal = () => {
    setFlightModalVisible(true);
  };

  const handleFlightOk = () => {
    // Add selected flights to the form
    form.setFieldsValue({ flights: [...(form.getFieldValue('flights') || []), ...selectedFlights] });
    setSelectedFlights([]);
    setFlightModalVisible(false);
  };

  const handleFlightCancel = () => {
    setSelectedFlights([]);
    setFlightModalVisible(false);
  };

  const onFlightCheck = (flight) => {
    setSelectedFlights(prev => {
      const isChecked = prev.some(e => e.id === flight.id);
      if (isChecked) {
        return prev.filter(e => e.id !== flight.id);
      //} else return null;
      } else {
        return [...prev, flight];
      } 
    });
  };

  // Function to show/hide Contact section
  const [showContactInfo, setShowContactInfo] = useState(false);
    
  // Style for rounded rectangles
  const sectionStyle = {
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
  };

  // Custom style to reduce spacing between form items
  const reducedMarginFormItem = { 
    marginBottom: '8px'
  };

  // Style for card headers
  const cardHeaderStyle = {
    backgroundColor: '#f0f2f5',
    color: '#1890ff'
  };

  return (
    <Form form={form} name="responsiveForm" onFinish={onFinish} layout="vertical" style={{ maxWidth: '800px', margin: '0 auto', backgroundColor: '#ddd', padding: '48px' }}>
      
      <Card
        title="Shipper Information"
        style={sectionStyle}
        headStyle={cardHeaderStyle}
        extra={ 
          <Button onClick={showModal} type="primary">
            New
          </Button>
        }
      >
        <Row gutter={[16, 8]}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Shipper Code"
              name="shipperCode"
              style={reducedMarginFormItem}
              rules={[{ required: true, message: 'Please select a customer code!' }]}
              hasFeedback
            >
              <Select
                showSearch
                placeholder="Select Shipper Code"
                onChange={handleCustomerCodeChange}
              >
                {customers.map(customer => (
                  <Option key={customer.code} value={customer.code}>{customer.code}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Shipper Name"
              name="shipperName"
              style={reducedMarginFormItem}
              rules={[{ required: true, message: 'Shipper name is required!' }]}
            >
              <Input disabled 
                value={customerName}
                placeholder="Shipper Name will auto-fill"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 8]}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Street Address"
              name="address"
              style={reducedMarginFormItem}
              rules={[{ required: true, message: 'Please fill the address!' }]}
            >
              <Input
                value={customerName}
                disabled
                placeholder="Address auto-fill"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="City"
              name="city"
              style={reducedMarginFormItem}
              rules={[{ required: true, message: 'City is required!' }]}
            >
              <Input
                value={customerName}
                disabled
                placeholder="City auto-fill"
              />
            </Form.Item> 
          </Col>
        </Row>
      </Card>

      <Card
        title="Flights"
        style={sectionStyle}
        headStyle={cardHeaderStyle}
        extra={
          <Button onClick={showFlightModal} type="primary">
            Add
          </Button>
        }
      >
        <Row gutter={[12, 8]}>
          <Form.Item className="flight-list">
            {/*<Form.Item label="Employees">*/}
            <div style={{ marginBottom: '10px', fontWeight: 'bold', width: '100%' }}>
              <Row gutter={8}>
                <Col span={8}>
                  <Row>
                    <Col sm={24} md={12}>Flight Nr</Col>
                    <Col sm={24} md={12}>Route</Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <Col sm={24} md={12}>Dep Date</Col>
                    <Col sm={24} md={12}>Dep Time</Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col sm={24} md={12}>Pieces</Col>
                    <Col sm={24} md={12}>Weight</Col>
                  </Row>
                </Col>
                <Col span={2}></Col> {/* Space for the remove button */}

              </Row>
            </div>
            <Form.List name="flights">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row gutter={4} key={field.key} style={{ marginBottom: 8 }}>
                      <Col span={8}>
                        <Row>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'flightnr']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Flight" />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'route']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Route" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={8}>
                        <Row>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'date']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Date" />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={12}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'time']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input disabled placeholder="Time" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6}>
                        <Row>
                          <Col sm={24} md={10}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'pieces']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input placeholder="Pieces" />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={14}>
                            <Form.Item className="listitem"
                              {...field}
                              name={[field.name, 'weight']}
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input placeholder="weight" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={2} className='remove-icon-wrapper'>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          style={{ marginTop: 8 }}
                        />
                      </Col>
                    </Row>
                  ))}
                  {/*<Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Employee
                    </Button>
                  </Form.Item>
                  <Button onClick={showEmployeeModal} type="primary" style={{ marginBottom: 8 }}>
                    Add Employees
                  </Button>*/}
                </>
              )}
            </Form.List>
          </Form.Item>
        </Row>
      </Card>
      <FormTestingList />
      <FlightsList /> 
      <FormTestingContactInfo />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>

      {/* Flight Selection Modal */}
      <Modal
        title="Select Flights"
        open={flightModalVisible} 
        onOk={handleFlightOk}
        onCancel={handleFlightCancel}
        width={600}
      >
        <List
          itemLayout="horizontal"
          dataSource={potentialFlights}
          renderItem={flight => (
            <List.Item>
              <Checkbox
                checked={selectedFlights.some(e => e.id === flight.id)}
                onChange={() => onFlightCheck(flight)}
              >
                {`${flight.route} - ${flight.flightnr}`}
              </Checkbox>
            </List.Item>
          )}
        />
      </Modal>

      {/* Modal for adding new customer */}
      <Modal title="Add New Customer" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onNewCustomerChange}
          initialValues={newCustomer}
        >
          <Form.Item
            label="Customer Code"
            name="route"
            rules={[{ required: true, message: 'Please input the customer code!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Customer Name"
            name="flightnr"
            rules={[{ required: true, message: 'Please input the customer name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Street Address"
            name="address"
            rules={[{ required: true, message: 'Please input the customer name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please input the customer name!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Form>

  );
};
