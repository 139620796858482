// src/components/FlightsList.js
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Modal, Checkbox, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AvailabilityObj } from './helpers/VuelosDisponibles';

export const FlightsList = ({ form }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [availableFlights, setAvailableFlights] = useState([]);
  const [loading, setLoading] = useState(false);




  // Fetch flights from an API when modal opens
  useEffect(() => {
    if (isModalVisible) {
      fetchFlights();
    }
  }, [isModalVisible]);

  const fetchFlights = async () => {
    setLoading(true);
    try {
      if (form.values.originAirportCode === form.values.destinationAirportCode) {
        form.message.error('Origin y Dest Are The Same');
        return
      }
      // Replace with your actual API endpoint
      //const response = await axios.get('https://your-api-endpoint.com/flights');

      const response = await AvailabilityObj(
        form.values.agents,
        form.values.destinationAirportCode,
        form.values.originAirportCode,
        form.values.weight,
        form.values.departureOn,
        form.auth.token,
        form.values.products);
      setAvailableFlights(response);//.data);
    } catch (error) {
      console.error('Error fetching flights:', error);
      setAvailableFlights([]);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Update the form with selected flights
    form.setFieldsValue({
      flights: selectedFlights.map(flight => ({
        flightNumber: flight.flightNumber,
        destination: flight.destination,
      })),
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setSelectedFlights([]);
    setIsModalVisible(false);
  };

  const onFlightChange = (flight) => {
    setSelectedFlights(prev => {
      const exists = prev.some(f => f.id === flight.id);
      if (exists) {
        return prev.filter(f => f.id !== flight.id);
      }
      return [...prev, flight];
    });
  };

  return (
    <Card title="Flights" style={{ marginBottom: 16 }}>
      <Form.List name="flights">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} gutter={8} style={{ marginBottom: 8 }}>
                <Col span={10}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'flightNumber']}
                    fieldKey={[field.fieldKey, 'flightNumber']}
                    rules={[{ required: true, message: 'Flight number is required!' }]}
                  >
                    <Input disabled placeholder="Flight Number" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'destination']}
                    fieldKey={[field.fieldKey, 'destination']}
                    rules={[{ required: true, message: 'Destination is required!' }]}
                  >
                    <Input disabled placeholder="Destination" />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={showModal} block icon={<PlusOutlined />}>
                Add Flight
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Modal
        title="Select Flights"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        confirmLoading={loading}
      >
        {loading ? (
          <p>Loading flights...</p>
        ) : (
          availableFlights.map(flight => (
            <Checkbox
              key={flight.id}
              checked={selectedFlights.some(f => f.id === flight.id)}
              onChange={() => onFlightChange(flight)}
            >
              {`${flight.flightNumber} - ${flight.destination}`}
            </Checkbox>
          ))
        )}
      </Modal>
    </Card>
  );
};

export default FlightsList;