import React, { useState } from 'react';
import { Form, Input, Select, Row, Col, Card, Button } from 'antd'
import { sectionStyle, cardHeaderStyle, cardBodyStyle, cardBodyHidden, reducedMarginFormItem } from '../formStyles';
import { ModalNewShipper } from './ModalNewShipper';

const { Option } = Select;

export const BookingShipper = ({ form }) => {

    const [participant, setParticipant] = useState('');

    // Function to show/hide Shipper section
    const [showShipperInfo, setShowShipperInfo] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    //SAMPLE CUSTOMER LIST
    const [participants, setCustomers] = useState([
        { code: 'CUST001', name: 'John Doe', city: 'New York', address: '123 Main St', zip: '10001', state: 'NY', country: 'USA' },
        { code: 'CUST002', name: 'Jane Smith', city: 'Boston', address: '456 Elm St', zip: '01001', state: 'MA', country: 'USA'  },
        { code: 'CUST003', name: 'Bob Johnson', city: 'Atlanta', address: '789 Oak St', zip: '25001', state: 'GA', country: 'USA'  },
    ]);

    const handleCustomerCodeChange = (value) => {
        const selectedCustomer = participants.find(customer => customer.code === value);
        if (selectedCustomer) {
            setParticipant(selectedCustomer.name);
            // Set the customer name in the form
            form.setFieldsValue({ 
                shpName: selectedCustomer.name, 
                shpAddress: selectedCustomer.address, 
                shpCity: selectedCustomer.city,
                shpState: selectedCustomer.state,
                shpCountry: selectedCustomer.country,
                shpPostalCode: selectedCustomer.zip
            });
        } else {
            setParticipant('');
            form.setFieldsValue({ shpName: '', address: '', city: '' }); // Clear the form fields if no customer is selected
        }
    };


    const showModalNew = () => {
        setIsModalVisible2(true);
    };

    return (
        <>
            {/* Section 3: Shipper Information with clickable title */}
            <Card
                title={
                    <div>

                        <div
                            onClick={() => setShowShipperInfo(!showShipperInfo)}
                            style={{ cursor: 'pointer', color: showShipperInfo ? 'inherit' : '#1890ff' }}
                        >
                            Shipper Information
                        </div>

                        <div
                            style={{
                                float: 'right',
                                display: showShipperInfo ? 'flex' : 'none',
                                marginBottom: '1rem'
                            }}
                        >

                            <Button
                                onClick={showModalNew}
                                type="primary"
                            >
                                New
                            </Button>
                        </div>
                    </div>
                }
                style={sectionStyle}
                headStyle={cardHeaderStyle}
                bodyStyle={showShipperInfo ? cardBodyStyle : cardBodyHidden}
                extra={null}
            >
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={12}>

                        <Form.Item
                            label="Shipper Code"
                            name="shpCode"
                            style={reducedMarginFormItem}
                            rules={[{ required: true, message: 'Please select a customer code!' }]}
                            hasFeedback
                        >
                            <Select
                                showSearch
                                placeholder="Select Shipper Code"
                                onChange={handleCustomerCodeChange}
                            >
                                {participants.map(customer => (
                                    <Option key={customer.code} value={customer.code}>{customer.code}</Option>
                                ))}

                            </Select>
                        </Form.Item>

                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label="Shipper Name"
                            name="shpName"
                            style={reducedMarginFormItem}
                            rules={[{ required: true, message: 'Shipper name is required!' }]}
                        >
                            <Input
                                value={participant}
                                placeholder="Shipper Name will auto-fill"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 8]}>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label="Street Address"
                            name="shpAddress"
                            style={reducedMarginFormItem}
                            rules={[{ required: true, message: 'Please fill the address!' }]}
                        >
                            <Input
                                value={participant}
                                disabled
                                placeholder="Address auto-fill"
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>

                        <Form.Item
                            label="City"
                            name="shpCity"
                            style={reducedMarginFormItem}
                            rules={[{ required: true, message: 'City is required!' }]}
                        >
                            <Input
                                value={participant}
                                disabled
                                placeholder="City auto-fill"
                            />
                        </Form.Item>

                    </Col>
                </Row>

                {
                    (showShipperInfo) && <ModalNewShipper
                        isModalVisible={isModalVisible2}
                        setIsModalVisible={setIsModalVisible2}
                        create='shipper'
                    />
                }

                {showShipperInfo && (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Postal Code"
                                name="shpPostalCode"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input the Postal Code' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="State"
                                name="shpState"
                                style={reducedMarginFormItem}
                                //rules={[{ required: true, message: 'Please input the name of the shipper!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {showShipperInfo && (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Country Code"
                                name="shpCountry"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input Country Code!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Card>
        </>
    )
}