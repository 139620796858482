import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { TrackingSummary } from './TrackingSummary';
import { ApiContext } from '../../context/ApiContext';
import { Spinner } from '../ui/Spinner';
import { ErrorMsg } from '../Modal/ErrorMsg';
import { consultar_reserva } from './helpers/consultar_reserva';
import { useWindow } from './hooks/useWindow';


export const TrackingBody = ( cleanBooking = false) => {

    const [ auth, guardarAuth] = useContext(ApiContext);
    const { token } = auth;
    
    const [ booking, setBooking] = useState({});
    const [ showSpinner, setShowSpinner] = useState(false);

    const [ showError, setShowError] = useState(false);
    const location = useLocation(); 

    const ancho = useWindow();
    
    const onFinish = ({serialNumber}) => {
        consultar(serialNumber);    
    };

    useEffect( () => {

        if(cleanBooking){
            setBooking({});
        } 
               
    }, [cleanBooking]);


    const consultar = async (serialNumber) => {

        setBooking({});

        setShowError(false);  
        setShowSpinner(true); 

        const respuesta = await consultar_reserva(serialNumber);
        
        setBooking(respuesta);   
        setShowSpinner(false);

        if(!respuesta){
            setShowSpinner(false);     
            setShowError(true);  
        }
        
    }

    const Share = () => {

        const url = window.location.origin; // obtengo la url del servidor
        const awbParams = `/awb-track?airlinePrefix=279&serialNumber=${booking.serialNumber}`       

        if (navigator.share) {
            navigator.share({
              title: 'Aeronex Cargo',
              text: 'AWB Tracking',
              url: `${url}${awbParams}`,
            })
            .then(() => console.log('Successfully shared'))
            .catch((error) => console.log('Error sharing', error));
          }
    }

    if(showSpinner){
        return <Spinner />
    }
      
    return(
        <>     
            {
                (showError) && <ErrorMsg  ver={true}/>
            }  

            <div className='formTrack'>

                <Form
                    name="horizontal"
                    layout="inline"
                    colon={false} 
                    size='small'  
                    wrapperCol={{
                        span: 10,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}                
                    autoComplete="off"
                >
                    <Form.Item 
                        label='Awb' 
                        name="airlinePrefix"   
                        style={{  width: ancho > 500? '20%' : '30%', margin: '0rem'}}                     
                        wrapperCol={{
                            span: 11
                        }}            
                    >
                        <Input 
                            type='text'
                            defaultValue='279'    
                            disabled                               
                        />

                    </Form.Item>

                    <Form.Item 
                        label='-' 
                        name="serialNumber" 
                        wrapperCol={{
                            span: 12,
                        }}  
                        style={{  width: ancho > 500 ? '30%' : '60%', margin: '0rem',}}    
                        rules={[
                            {
                                required: true,
                                message: 'Please input AWB Number',
                            },
                            {
                                pattern: /^[0-9]+$/,
                                message: 'Numeric value required',
                            }, 
                            {max: 8, message: 'Max 8 digits'}
                        ]}
                        hasFeedback                 
                    >
                        <Input  
                            type='text'
                            placeholder='Number'                                           
                        />

                    </Form.Item>    
               
                    <Form.Item className='btn'>

                        <Button 
                            type="primary" 
                            htmlType="submit" 
                            style={{
                                backgroundColor: '#1D2758', 
                                color: 'white',                          
                            }} 
                        > 
                            Submit
                        </Button>

                    </Form.Item>  

                    <Form.Item className='btn'>

                        <Button 
                            type='primary'                            
                            onClick={Share}
                            disabled={Object.entries(booking).length === 0}
                            style={{
                                backgroundColor: '#1D2758', 
                                color: 'white',                                  
                            }} 
                        > 
                            Share
                        </Button>
                    </Form.Item>                       
                
                </Form>  
        </div>      
      

            {
               (Object.entries(booking).length > 0) && <TrackingSummary booking={booking} />           
            } 
       </>
    )
}