import React, { useState } from 'react';
import { Form, Input, Select, Row, Col, Card, Button } from 'antd'
import { sectionStyle, cardHeaderStyle, cardBodyStyle, cardBodyHidden, reducedMarginFormItem } from '../formStyles';
import { ModalNewConsignee } from './ModalNewConsignee';

export const BookingConsignee = ({form}) => {

    // Function to show/hide Shipper section
    const [showShipperInfo, setShowShipperInfo] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);


    const serializeForm = (values) => {
        const formData = form.getFieldsValue();
        console.log('Serialized Form Data:', values);
        const jsonData = JSON.stringify(formData);
        console.log('JSON String:', jsonData);
    };

    const showModalConsignee = () => {
        setIsModalVisible2(true);
    }

    return (
        <>
            {/* Section 3: Shipper Information with clickable title */}
            <Card
                title={
                    <div>
                        <div
                            onClick={() => setShowShipperInfo(!showShipperInfo)}
                            style={{ cursor: 'pointer', color: showShipperInfo ? 'inherit' : '#1890ff' }}
                        >
                            Consignee Information
                        </div>

                        <div 
                            style={{
                                float: 'right',
                                display: showShipperInfo ? 'flex' : 'none',
                                marginBottom: '1rem'
                            }}
                        >

                            <Button
                                onClick={showModalConsignee}
                                type="primary"
                            >
                                New
                            </Button>
                        </div>
                    </div>
                }

                style={sectionStyle}
                headStyle={cardHeaderStyle}
                bodyStyle={showShipperInfo?cardBodyStyle:cardBodyHidden}
                extra={null} // Remove any default extra content
            >
                {
                    (showShipperInfo) && <ModalNewConsignee
                        create='consignee'
                        isModalVisible2={isModalVisible2}
                        setIsModalVisible2={setIsModalVisible2}
                    />
                }
              
                {showShipperInfo && (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Customer Id"
                                name="CneId"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Business Name"
                                name="CneName"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input the name of the shipper!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {showShipperInfo && (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Address"
                                name="CneAddress"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input your address!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="City"
                                name="CneCity"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input the city!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {showShipperInfo && (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Postal Code"
                                name="CnePostalCode"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input the city!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Country"
                                name="CneCountry"
                                style={reducedMarginFormItem}
                                rules={[{ required: true, message: 'Please input the country!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Card>
        </>
    )
}