import React from 'react';
import { Button, Modal } from 'antd';
import { FormNewShipperConsignee } from './FormNewShipperConsignee';
//import { FormSectionShipper } from './FormSectionShipper';



export const ModalNewConsignee = ({ isModalVisible2, setIsModalVisible2, create }) => {

  const handleEmployeeCancel = () => {  
    setIsModalVisible2(false);
  };

  return (
    <>         
      {/*<FormSectionShipper />*/}
      
        <Modal
            title={`Create new ${create}`}
            open={isModalVisible2}  
            onCancel={handleEmployeeCancel}
            width={600}  
            footer={[               
                <Button 
                    key={1}
                    onClick={handleEmployeeCancel} 
                    style={{backgroundColor: 'red', color: 'white'}}
                >
                    Cancel
                </Button>              
            ]}          
        >
          <FormNewShipperConsignee
             create={create}
             setModalVisible={setIsModalVisible2}
          />

        </Modal>
    </>
  )
}


