
//const BookingForm = ({ initialBooking, onSubmit }) => {};
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Input, Button, Card, Select, Table, Row, Col } from 'antd';
import 'react-datepicker/dist/react-datepicker.css';
import * as s from '../formStyles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

export function AnnouncementsForm() {
  const [formData, setFormData] = useState({
    subject: '',
    content: '',
    startDate: null,
    endDate: null
  });

  // Quill editor toolbar configuration
  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image'
  ];

  // Special Handling codes with 3-letter keys
  const bookingTypes = [
    { name: 'General Cargo', key: 'GEN' },
    { name: 'Perishables', key: 'PER' },
    { name: 'Flowers', key: 'PEF' },
    { name: 'Apparel', key: 'APP' },
  ];

  // Custom tag render to show both name and key
  const customTagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const type = bookingTypes.find(t => t.key === value) || { name: value, key: value };

    return (
      <span
        style={{
          background: '#f5f5f5',
          padding: '0 7px',
          marginRight: 8,
          borderRadius: 2,
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {`(${type.key}) ${type.name}`}
        {closable && (
          <span
            onClick={onClose}
            style={{ marginLeft: 4, cursor: 'pointer' }}
          >
            ×
          </span>
        )}
      </span>
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleContentChange = (value) => {
    setFormData(prev => ({
      ...prev,
      content: value
    }));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFormData(prev => ({
      ...prev,
      startDate: start,
      endDate: end
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the data to your backend
    console.log('Form submitted:', {
      subject: formData.subject,
      content: formData.content,
      timeframe: {
        start: formData.startDate,
        end: formData.endDate
      }
    });
    // Reset form after submission
    setFormData({
      subject: '',
      content: '',
      startDate: null,
      endDate: null
    });
  };

  return (

    <div className="announcement-form">
      <Card
        title="Create New Announcement"
        style={s.sectionStyle}
        headStyle={s.cardHeaderStyle}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              placeholder="Enter announcement subject"
              required
            />
          </div>
          <Row>
            <Col xs={24} sm={24} md={10}>
              <div className="form-group">
                <label>Timeframe</label>
                <DatePicker
                  selectsRange
                  startDate={formData.startDate}
                  endDate={formData.endDate}
                  onChange={handleDateChange}
                  dateFormat="MMMM d, yyyy"
                  placeholderText="Select display timeframe"
                  isClearable
                  required
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={10}>
              <Form.Item
                name="target"
                label="Targets"
                rules={[{ required: true, message: 'Please select at least one target' }]}
              >
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Select or add Handling Codes"
                  allowClear
                  tagRender={customTagRender}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <div style={{ padding: 8 }}>
                        <span style={{ color: '#999' }}>
                          Type to add custom types (will use first 3 letters as key)
                        </span>
                      </div>
                    </>
                  )}
                  onChange={(values) => {
                    // Convert new custom types to 3-letter keys
                    const processedValues = values.map(value => {
                      const foundType = bookingTypes.find(t => t.key === value);
                      if (foundType) return value;
                      return value.substring(0, 3).toUpperCase();
                    });
                    formData.setFieldsValue({ types: processedValues });
                  }}
                >
                  {bookingTypes.map((type) => (
                    <Option key={type.key} value={type.key}>
                      {type.name} ({type.key})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="form-group">
            <label>Content</label>
            <ReactQuill
              value={formData.content}
              onChange={handleContentChange}
              modules={quillModules}
              formats={quillFormats}
              placeholder="Write your announcement here..."
            />
          </div>

          <button type="submit">Publish Announcement</button>
        </form>
      </Card>
      <style jsx>{`
        .announcement-form {
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
        }
        .form-group {
          margin-bottom: 20px;
        }
        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }
        input[type="text"] {
          width: 100%;
          padding: 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        button {
          background-color: #007bff;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        button:hover {
          background-color: #0056b3;
        }
      `}</style>
    </div>
  );
}

export default AnnouncementsForm;
