
import React, { useState, useContext } from 'react'
import { useNavigate, Link} from 'react-router-dom';
import { Button,  Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { consultar_agent } from '../reservas/helpers/bookingAgent';
import { ApiContext } from '../../context/ApiContext';
import { ApiLogin } from '../../api/login';
import { MsgError } from '../ui/MsgError';
import { Spinner } from '../ui/Spinner';


export const FormLogin = () => {

    const [ auth, guardarAuth ] = useContext(ApiContext);
    const [showError, setShowError] = useState(false); 

    const [btnEnviar, setBtnEnviar] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();   

    const onFinish = async ({user, password}) => {
    
        try {
          
          setBtnEnviar(true);
          setShowSpinner(true);
          
          const respuesta = await ApiLogin.post('Authenticate/login', {
                username: user,
                password
          });
    
          const { sessionid, agents, username, changepassword } = respuesta.data;
    
          localStorage.setItem('token', sessionid);
          //localStorage.setItem('agents', agents);
          console.log('Login Agents:', agents);
          //Esta funcion debe llamarse cada vez que se carga el formulario New Booking
          const ConsultarBookingAgent = await consultar_agent(sessionid);      
          localStorage.setItem('booking', ConsultarBookingAgent);
          console.log('Login Booking Form:', ConsultarBookingAgent);

          guardarAuth({ 
                token: sessionid,
                auth: true,
                agents: agents?? ConsultarBookingAgent.agents, //corregir
                destinations: ConsultarBookingAgent.destinations,
                origins: ConsultarBookingAgent.origins,
                products: ConsultarBookingAgent.products,
                shc: ConsultarBookingAgent.shc,            
                username,
          }); 
                
          setShowSpinner(false);
            
          navigate('/form');
          
        } catch (error) {     
    
          setShowError(true); 
          setShowSpinner(false);
          setBtnEnviar(false);      
          
        }     
        
      };  

    if(showSpinner){
         return <Spinner />
    }

  return (

    <div className='login'>                

        {
            (showError) && <MsgError mostrar={true}  />                     
        }

        <Form
            name="basic"
            size='small'
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 18,
            }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item          
                label={ <UserOutlined style={{fontSize: 16}} />}
                className='placehold' 
                name="user"               
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='Username' />
            </Form.Item>

            <Form.Item
                label={<LockOutlined style={{fontSize: 16}}/>}
                name="password"
                style={{marginBottom: '0.4rem'}}
                rules={[
                    {
                    required: true,
                    message: 'Required', 
                    },
                ]}
            >
                <Input.Password   placeholder='Password'/>
            </Form.Item>

            <div className='acciones'  style={{ width: '91%', }}>                      
                <Link  to='/reset'> Forgot Password </Link>      
            </div>
            
            <div className='contenedor_btn'>

                <Form.Item>

                    <Button 
                        type="primary" 
                        htmlType="submit"
                        style={{backgroundColor: '#1D2758', color: 'white'}}
                        disabled={btnEnviar}
                    >
                        LOGIN
                    </Button>
                </Form.Item>
            </div>
        </Form> 
    </div> 
        
  )
}


