import { Availability } from '../../../api/Availability';
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique IDs

export const AvailabilityObj = async (agent, destination, origin, totalWeight, date, token, products) => {

    const params = new URLSearchParams();

    params.append('accountNumber', agent);
    params.append('carrierCodes', 'B6');
    params.append('originAirportCode', origin);
    params.append('destinationAirportCode', destination);
    params.append('departureOn', date);
    params.append('weight', totalWeight);
    params.append('ProductCode', products);

    try {
        const respuesta = await Availability.get('/availability', {
            params,
            headers: {
                Accept: 'application/json',
                sessionid: token
            }
        }
        );
        // Transform API response, adding route IDs
        const transformedFlights = respuesta.data.routes.map(flight => ({
            id: (flight.segments || []).map(segment => segment.transportMeans.reference).join('-')||uuidv4(),
            segments: (flight.segments || []),
            price: flight.price
        }));
        return transformedFlights;

    } catch (error) {
        console.log(error);

        return [];
    }

}




