import React, { useState } from 'react';
import { Form, Input, Row, Col, Card, Select,  Checkbox } from 'antd';
import { sectionStyle, cardHeaderStyle, reducedMarginFormItem } from '../formStyles';

const { Option } = Select;

export const FormTestingContactInfo = () => {

  // Function to show/hide Contact section
  const [showContactInfo, setShowContactInfo] = useState(false);

  return (
    <>
      {/* Toggle for showing/hiding Shipper Information */}
      <Form.Item>
        <Checkbox onChange={e => setShowContactInfo(e.target.checked)}>
          Show Contact Information (Test)
        </Checkbox>
      </Form.Item>

      {/* Section 2: Contact Information */}
      {showContactInfo && (
        <Card style={sectionStyle} headStyle={cardHeaderStyle}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Business Name"
                name="ShipperName"
                style={reducedMarginFormItem}
                rules={[{ required: true, message: 'Please input the name of the shipper!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Address"
                name="address"
                style={reducedMarginFormItem}
                rules={[{ required: true, message: 'Please input your address!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="City"
                name="ShipperCity"
                style={reducedMarginFormItem}
                rules={[{ required: true, message: 'Please input the city!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Country"
                name="ShipperCountry"
                style={reducedMarginFormItem}
                rules={[{ required: true, message: 'Please input the country!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default FormTestingContactInfo;