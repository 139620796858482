import React, { useState } from 'react';
import { Form, Input, Button, Card, Table, Row, Col } from 'antd';
import AnnouncementsForm from './AnnouncementsForm';
import * as s from '../formStyles';
import AcsApi from '../../api/AcsApi';

export const AnnounsementsMain = () => {
  const [searchForm] = Form.useForm();
  const [bookings, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserForm, setShowUserForm] = useState(false);
  //const token = localStorage.getItem('token');

  const  onSearch = async (values) => {

        const params = new URLSearchParams();
    
        (values.subject)&&params.append('subject', values.subject);
        (values.startDate)&&params.append('startDate', values.startDate);
        (values.endDate)&&params.append('endDate', values.endDate);
        (values.active)&&params.append('active', values.active);

        const response = await AcsApi.get('/acs/users/v1/list', {
            params,
        });
        console.log(response);
        console.log(response.data);
        setUsers(response.data); 

    // Simulated API call
     const mockUsers = [
       { subject: 'JLONDON', startdate: 'John', lastname: 'London', email: 'John@mymail.com', agents: ['UTST001', 'EXP'] },
       { subject: 'JSMITH', startdate: 'Jane', lastname: 'Smith', email: 'Jane@Smith.ca', agents: ['UTST002'] },
       { subject: 'CHRENDEL', startdate: 'Christian', lastname: 'Rendel', email: 'Bob@Lee.com', agents: ['UTST001', 'UTST002'] },
     ].filter(user =>
       (!values.username || user.username.toLowerCase().includes(values.username.toLowerCase())) &&
       (!values.name || user.name.toLowerCase().includes(values.name.toLowerCase())) &&
       (!values.lastname || user.lastname.toLowerCase().includes(values.lastname.toLowerCase())) &&
       (!values.email || user.email.toLowerCase().includes(values.email.toLowerCase()))
     );
     setUsers(mockUsers);
  };

  const handleUserSubmit = (userData) => {
    console.log('User submitted:', userData);
    setSelectedUser(null);
    searchForm.submit();
  };

  const handleEditUser = (booking) => {
    setSelectedUser(booking);
    setShowUserForm(true);
  };

  const handleBack = () => {
    setSelectedUser(null);
    setShowUserForm(false);
  };

  const columns = [
    { title: 'ID', dataIndex: 'username', key: 'username' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Lastname', dataIndex: 'lastname', key: 'lastname' },
    { title: 'E-Mail', dataIndex: 'email', key: 'email' },
    //{ title: 'Agents', dataIndex: 'agents', key: 'agents', render: types => types.join(', ') },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => handleEditUser(record)}>Edit</Button>
      ),
    },
  ];

  return (
    <div style={s.formStyle}>
      <Col span={24}>

        {showUserForm ? (
          <AnnouncementsForm
            initialUser={selectedUser}
            onSubmit={handleUserSubmit}
            onBack={handleBack}
          />
        ) : (
          <Card
            title="Search Users"
            style={s.sectionStyle}
            headStyle={s.cardHeaderStyle}
          >
            <Form form={searchForm} layout="vertical" onFinish={onSearch}>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="subject" label="Subject">
                    <Input placeholder="Enter Subject" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="startdate" label="Start Date">
                    <Input placeholder="Enter Start Date" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="enddate" label="End Date">
                    <Input placeholder="Enter End Date" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="active" label="Active">
                    <Input placeholder="Enter Active" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">Search</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form >
            <Table style={{padding: '0.5rem',}} dataSource={bookings} columns={columns} rowKey="id" />
          </Card >
        )}
      </Col >
    </div >
  );
};

export default AnnounsementsMain;